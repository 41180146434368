@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

* {
    margin: 0;
    padding: 0;
    box-shadow: border-box;
}

a.navbar-brand img {
    width: 120px;
}

#body {
    font-family: 'Centra', sans-serif !important;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.app {
    width: 100%;
    height: 100%;
    max-width: 1000px;
    width: 900px;
    background: #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
}

    .app > .screen {
        display: none;
    }

        .app > .screen.active {
            display: block;
            width: 100%;
            height: 100%;
        }

.screen .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
}

.screen .form-input {
    width: 100%;
    margin: 20px 0;
}

.screen h2 {
    margin-bottom: 20px;
    font-size: 30px;
    color: #111;
    border-bottom: 4px solid #555;
    padding: 5px 0;
    display: inline-block;
}

.screen .form-input label {
    display: block;
    margin-bottom: 5px;
}

.screen .form-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #555;
    font-size: 16px;
}

.screen .form-input button {
    padding: 10px 20px;
    background: linear-gradient(90.21deg,#aa367c -5.91%,#4a2fbd 111.58%);
    color: #eee;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border: none;
}

.chat-screen .header {
    background-image: url(./assets/img/footer-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

    .chat-screen .header .logo {
        font-size: 18px;
        color: #eee;
        font-weight: 600;
    }

    .chat-screen .header button {
        padding: 5px 10px;
        border: 1px solid #eee;
        background: transparent;
        color: #eee;
        font-size: 15px;
        cursor: pointer;
        outline: none;
    }

.chat-screen .messages {
    width: 100%;
    height: calc(100% - 100px);
    background: #f5f5f5;
    overflow: auto;
}

    .chat-screen .messages .message {
        display: flex;
        padding: 10px;
    }

        .chat-screen .messages .message > div {
            max-width: 80%;
            background: #fff;
            box-shadow: 0 0 20px 5px rgba(0,0,0,0,0.5);
            padding: 10px;
        }

        .chat-screen .messages .message.my-message {
            justify-content: flex-end;
        }

        .chat-screen .messages .message.other-message {
            justify-content: flex-start;
        }

        .chat-screen .messages .message .name {
            font-size: 13px;
            color: #555;
            margin-bottom: 5px;
        }

        .chat-screen .messages .message .text {
            word-wrap: break-word;
        }

    .chat-screen .messages .update {
        text-align: center;
        padding: 10px;
        font-style: italic;
    }

.chat-screen .typebox {
    width: 100%;
    height: 50px;
    display: flex;
}

    .chat-screen .typebox input {
        flex: 1;
        height: 50px;
        font-size: 18px;
        padding-left: 10px;
    }

    .chat-screen .typebox button {
        width: 80px;
        height: 100%;
        background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
        color: #eee;
        font-size: 16px;
        outline: none;
        border: none;
        cursor: pointer;
    }
